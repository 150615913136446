<template>
  <div v-show="commentExists" class="row">
    <div class="col-3 text-end">
      <img src="@/assets/stickers/check-40.png" alt="check" class="img-fluid"/>
    </div>
    <div class="col text-start">
      {{ commentSend ? 'Het commentaar is verstuurd...' : 'Het commentaar bij deze oefening is vandaag al verstuurd...' }}
    </div>
  </div>
  <div v-show="!commentExists" class="row">
    <div class="col">
      <div class="row mb-2">
        <div class="col fw-bold">
          Hoe ging de oefening?
        </div>
      </div>
      <div class="row">
        <div class="col ms-3 me-3">
          <input type="range" class="form-range score-range" :min="0" :max="10" v-model="score">
        </div>
      </div>
      <div class="row mb-2">
        <div class="col text-start">
          <img src="@/assets/stickers/sad-48.png" alt="happy" class="img-fluid" style="width: 40px;"/>
        </div>
        <div class="col text-end">
          <img src="@/assets/stickers/happy-48.png" alt="sad" class="img-fluid" style="width: 40px;"/>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <textarea class="form-control text-secondary scrollbar-text" style="resize: none; height: 120px;" v-model="comments"></textarea>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button type="button" class="btn btn-primary opacity-50" @click="sendExerciseComments()">Verstuur</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ClientPortalDataService from "@/firebase/ClientPortalDataService";
import store from '@/store'

export default {
  name: "ExerciseScoring",
  props: {
    employeeId: String,
    exerciseId: String,
  },
  data() {
    return {
      score: 0,
      comments: '',
      commentExists: false,
      commentSend: false,
    }
  },
  methods: {
    async sendExerciseComments() {
      const exerciseCommentObj = {
        score: this.score,
        comments: this.comments,
        created: new Date(),
      }
      await ClientPortalDataService.AddExerciseComment(this.clientId, this.dossierId, this.exerciseId, exerciseCommentObj)

      const dataObj = {
        created: new Date(),
        type: 'exercise_comment',
        client_id: this.clientId,
        dossier_id: this.dossierId,
        exercise_id: this.exerciseId,
        status: 'open',
      }
      await ClientPortalDataService.AddAlert(this.employeeId, dataObj)

      this.commentSend = true
      this.commentExists = true
    },
  },
  computed: {
    clientId() {
      return store.state.currentClientId
    },
    dossierId() {
      return store.state.dossierId
    },
  },
  async mounted() {
    let exists = false
    const comments = await ClientPortalDataService.GetExerciseComments(this.clientId, this.dossierId, this.exerciseId)
    comments.forEach((comment) => {
      if (exists === false) {
        exists = (new Date().toDateString() === new Date(comment.created.seconds * 1000).toDateString())
      }
    })
    this.commentExists = exists
    this.commentSend = false
  }
}
</script>

<style scoped>

</style>