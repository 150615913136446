<template>
  <RouterView/>
</template>

<script>

export default {
  name: 'App',
  components: {

  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Nunito');

#app {
  font-family: 'Nunito', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: ghostwhite;
  margin-top: 24px;
}

.html {
  background-color: blue;
}

.dropdown-item:hover {
  font-weight: bold;
  background-color: rgb(245,252,253);
}

.custom-shape-divider-bottom-1693904851 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1693904851 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 110px;
}

.custom-shape-divider-bottom-1693904851 .shape-fill {
  fill: #4A90E2;
}
</style>
