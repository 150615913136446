import questionnaire_4dkl_v1 from "@/components/clinimetrics/assets/4dkl_v1";
import questionnaire_gpe_dv_v1 from "@/components/clinimetrics/assets/gpe-dv_v1";
import questionnaire_hws_v1 from "@/components/clinimetrics/assets/hws_v1";

class ClinimetricsDataService {
    GetClinimetricsData(clinimetricsId){
        switch (clinimetricsId){
            case 'hws':
            case 'HWS_v1':
                return questionnaire_hws_v1
            case '4dkl':
            case '4DKL_v1':
                return questionnaire_4dkl_v1
            case 'GPE-DV_v1':
                return questionnaire_gpe_dv_v1
            default:
                return {
                    description: '',
                    questions: [],
                }
        }
    }
}

export default new ClinimetricsDataService()