import { createMemoryHistory, createRouter } from 'vue-router'
import store from '@/store'

import CalendarTile from "@/components/calendar/CalendarTile";
import DossierTile from "@/components/dossier/DossierTile";
import InvoiceTile from "@/components/invoices/InvoiceTile";
import MessageTile from "@/components/messages/MessageTile";
import WelcomeTile from "@/components/main/WelcomeTile";
import ExerciseTile from "@/components/exercises/ExerciseTile";
import RequestTile from "@/components/requests/RequestTile";

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("./components/auth/LoginScreen"),
        meta: {
            guest: true,
        }
    },
    {
        path: "/reset-password",
        name: "reset-password",
        component: () => import("./components/auth/ResetScreen.vue"),
        meta: {
            guest: true,
        }
    },
    {
        path: "/registration",
        name: "registration",
        component: () => import("./components/auth/RegistrateScreen"),
        meta: {
            guest: true,
        }
    },
    {
        path: '/',
        component: WelcomeTile
    },
    {
        path: '/calendar',
        component: CalendarTile
    },
    {
        path: '/invoices',
        component: InvoiceTile
    },
    {
        path: '/messages',
        component: MessageTile
    },
    {
        path: '/dossier',
        component: DossierTile
    },
    {
        path: '/exercises',
        component: ExerciseTile,
    },
    {
        path: '/requests',
        component: RequestTile,
    },
]

const router = createRouter({
    history: createMemoryHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(route => route.meta.guest)) {
        return next();
    }

    if (store.state.currentClientId) {
        return next();
    }

    return next({ name: 'login' });
});

export default router;