import store from '@/store';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { collection, getDocs } from 'firebase/firestore';
import { db, auth } from '@/firebase/init';

export async function login(email, password) {
    const result = await signInWithEmailAndPassword(auth, email, password)

    await setCurrentEmployeeAndCustomer(result.user.email);
}

export async function reset(email) {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email, {
        url: `${window.location.origin}/login`,
    })
}

export async function logout() {
    await getAuth().signOut();
    await clear();
}

export async function setCurrentEmployeeAndCustomer(email) {
    const clients = await getDocs(collection(db, 'users', email, 'clients'))
    const firstClient = clients.docs.length ? clients.docs[0] : null

    if (!firstClient) {
        throw new Error(`Could not authenticate ${email}`)
    }

    await store.dispatch('setCurrentClientId', firstClient.data().client_id)
}

export async function clear() {
    await store.dispatch('setCurrentClientId', null);
}
