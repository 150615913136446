<template>
  <div v-if="question.question_type === 'multiple_choice_vertical'" class="row mb-3 ps-3 pe-3">
    <div class="col">
      <div v-for="(item, index) in question.items" :key="item" class="row text-start mb-2">
        <div class="col">
          <input class="form-check-input" type="radio" v-model="score" :id="'question_' + question.number + '_answer_' + index" :value="item.value" @change="processScore(item.value)">
          <label class="form-check-label ms-2" :for="'question_' + question.number + '_answer_' + index">
            {{ item.description }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "MultipleChoiceVertical",
  props: ['question'],
  data() {
    return {
      score: '',
    }
  },
  methods: {
    nextQuestion() {

    },
    processScore(score) {
      store.state.questionnaire.questions[store.state.currentQuestion - 1].score = score
      setTimeout(() => {
        if (store.state.currentQuestion < store.state.questionnaire.questions.length) {
          store.state.currentQuestion++
        }
      },500)
    }
  }
}
</script>

<style scoped>

</style>