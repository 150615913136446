<template>
  <div class="button" :class="enabled ? 'button-enabled' : 'button-disabled'">
    <img :src="require('@/assets/icons/' + image)" alt="Calendar" class="img-fluid"/>
    <div class="mt-3 ps-0 pe-0">
      {{ label }}
      <span v-show="counter > 0" :class="badgecolor">
        {{ counter }}
      </span>
    </div>

  </div>
</template>

<script>
export default {
  name: "MenuButton",
  props: {
    label: String,
    image: String,
    enabled: Boolean,
    counter: Number,
    badgecolor: String,
  },
}
</script>

<style scoped>
.button {
  background-color: transparent;

  width: 90%;
  padding: 8px;
  margin: auto;
  border-radius: 8px;
}
.button-enabled {
  transition: background-color 0.4s linear;
}
.button-disabled {
  filter: grayscale(100);
}
.button-enabled:hover {
  background-color: rgba(74, 144, 226, 0.2);
  font-weight: bold;
  /*box-shadow: 4px 4px 4px rgba(243, 242, 239, 0.6);*/
}
.button-badge-red {
  background-color: #fa3e3e;
  border-radius: 8px;
  color: white;

  padding: 1px 5px;
  font-size: 10px;

  position: relative; /* Position the badge within the relatively positioned button */
  top: -6px;
  left: -2px;
  /*right: 0;*/
}
.button-badge-blue {
  background-color: rgba(55, 155, 237, 0.78);
  border-radius: 8px;
  color: white;

  padding: 1px 5px;
  font-size: 10px;

  position: relative; /* Position the badge within the relatively positioned button */
  top: -6px;
  left: -2px;
  /*right: 0;*/
}
</style>