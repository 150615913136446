// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDFSuSq9l0WpxhGKm_inhCwtoLa9U6zk-M",
    authDomain: "nora-client-portal.firebaseapp.com",
    projectId: "nora-client-portal",
    storageBucket: "nora-client-portal.appspot.com",
    messagingSenderId: "659980217994",
    appId: "1:659980217994:web:3896248cfc057769468773",
    measurementId: "G-8TJ0RYFQM4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app)
export const auth = getAuth(app)

