<template>
  <div v-show="questionnaire.questions.length > 0" class="card shadow-sm">
    <div class="card-header text-secondary">
      <div class="row">
        <div class="col-2"/>
        <div class="col">
          {{ questionnaire ? questionnaire.description : '' }}
        </div>
        <div class="col-2 text-end">
          <button type="button" class="btn-close" @click="store.state.clinimetricsId = ''"></button>
        </div>
      </div>
    </div>
    <div class="card-body text-secondary">
      <div class="row text-secondary">
        <div v-for="(question) in questionnaire.questions" :key="question" v-show="currentQuestion === question.number">
          <div v-show="question.info !== ''" class="row ms-3 me-3 mb-4 shadow-sm" style="background-color: rgba(74,144,226,0.2)">
            <div class="col fst-italic">
              {{ question.info }}
            </div>
          </div>
          <div class="row ps-3 pe-3 mb-3">
            <div class="col text-start">
              {{ question.description }}
            </div>
          </div>

          <multiple-choice-min-max-horizontal v-if="question.question_type === 'multiple_choice_min_max_horizontal'" :question="question"/>

          <multiple-choice-vertical v-if="question.question_type === 'multiple_choice_vertical'" :question="question"/>

          <div class="row mt-5">
            <div class="col text-end">
              <img v-show="currentQuestion === 1" src="@/assets/icons/next-24.png" alt="" class="img-fluid reverse gray"/>
              <img v-show="currentQuestion !== 1" src="@/assets/icons/next-24.png" alt="" class="img-fluid reverse" @click="onNextClick(false)"/>
            </div>
            <div class="col-3 text-center">
              {{ question.number }}/{{ questionnaire.questions.length }}
            </div>
            <div class="col text-start">
              <img v-show="currentQuestion !== maxQuestion" src="@/assets/icons/next-24.png" alt="" class="img-fluid" @click="onNextClick(true)"/>
              <img v-show="currentQuestion === maxQuestion" src="@/assets/icons/next-24.png" alt="" class="img-fluid gray"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button v-show="!verificationResult" type="button" class="btn btn-secondary opacity-75 align-middle me-3" disabled>
        Verstuur
      </button>
      <button v-show="verificationResult" type="button" class="btn btn-success opacity-75 align-middle me-3" @click="processClinimetrics()">
        Verstuur
      </button>
      <button type="button" class="btn btn-secondary opacity-75" @click="store.state.clinimetricsId = ''">
        Annuleren
      </button>
<!--      <div v-for="(question) in questionnaire.questions" :key="question">-->
<!--        <div class="row">-->
<!--          <div class="col text-start">-->
<!--            Antwoord {{ question.number }}: {{ question.score }}-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import MultipleChoiceMinMaxHorizontal from "@/components/clinimetrics/MultipleChoiceMinMaxHorizontal";
import store from "@/store";
import MultipleChoiceVertical from "@/components/clinimetrics/MultipleChoiceVertical";
import ClinimetricsFormulaService from "@/components/clinimetrics/services/ClinimetricsFormulaService";
import ClientPortalDataService from "@/firebase/ClientPortalDataService";

export default {
  name: "ClinimetricsTile",
  components: {MultipleChoiceVertical, MultipleChoiceMinMaxHorizontal},
  props: ['clinimetricsId'],
  data() {
    return {
      store,
      verificationResult: false,
    }
  },
  methods: {
    onNextClick(state) {
      //this.validateAnswers()
      if (state === true) {
        store.state.currentQuestion++
      } else {
        store.state.currentQuestion--
      }
    },
    validateAnswers() {
      let result = true
      for (let i = 0; i < this.questionnaire.questions.length; i++) {
        if (this.questionnaire.questions[i].score === '') {
          result = false
        }
      }

      this.verificationResult = result
    },
    async processClinimetrics() {
      let answers = []
      this.questionnaire.questions.forEach((question) => {
        answers.push({
          'answer': question.score,
        })
      })

      let scores = []
      this.questionnaire.scores.forEach((score) => {
        scores.push({
          'description': score.description,
          'score': ClinimetricsFormulaService.countFormula(this.questionnaire, score.formule),
          'max_value': score.max,
        })
      })

      await ClientPortalDataService.UpdateRequest(store.state.currentClientId, store.state.dossierId, store.state.currentRequest.id, {
        answers: answers,
        scores: scores,
        status: 'done',
      })

      const dataObj = {
        created: new Date(),
        type: 'clinimetrics',
        client_id: store.state.currentClientId,
        dossier_id: store.state.dossierId,
        request_id: store.state.currentRequest.id,
        status: 'done',
      }
      await ClientPortalDataService.AddAlert(store.state.client.employee_id, dataObj)

      store.state.clinimetricsId = ''
    },
  },
  computed: {
    questionnaire() {
      return store.state.questionnaire
    },
    currentQuestion() {
      return store.state.currentQuestion
    },
    maxQuestion() {
      return this.questionnaire.questions.length
    },
  },
  watch: {
    clinimetricsId() {

    },
    questionnaire: {
      handler() {
        this.validateAnswers()
      },
      deep: true,

    }
  }
}
</script>

<style scoped>
.reverse {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.gray {
  filter: grayscale(100%);
}
</style>