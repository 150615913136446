const questionnaire_hws_v1 = {
    'id': 'HWS_v1',
    'description': 'Haptotherapeutische Welbevinden Schaal (HWS)',
    'short': 'HWS',
    'questions': [
        {
            'number': 1,
            'info': 'Deze vragenlijst gaat over gevoelens en gedachten die u had in de afgelopen week. Het antwoord ' +
            'op elke vraag wordt gegeven op een schaal van 1 t/m 5. De uitersten op de schaal (1 en 5) ' +
            'vertegenwoordigen de contrasten van een bepaald gevoel of een bepaalde gedachte. U kunt ' +
            'antwoorden door het cijfer te omcirkelen dat het beste overeenkomt met uw gevoelens en ' +
            'gedachten.',
            'description': 'Hoe tevreden was u over uw levenswelzijn?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'volkomen ongelukkig',
            'max_description': 'super gelukkig',
            'score': '',
        },
        {
            'number': 2,
            'info': '',
            'description': 'Hoe verliep uw lichamelijk bewegen?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'onhandig en stijf',
            'max_description': 'soepel en harmonieus',
            'score': '',
        },
        {
            'number': 3,
            'info': '',
            'description': 'Hoe was uw spierspanning?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'zeer hoge spierspanning',
            'max_description': 'ontspannen',
            'score': '',
        },
        {
            'number': 4,
            'info': '',
            'description': 'Had u zelfvertrouwen?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'zeer onzeker',
            'max_description': 'vol zelfvertrouwen',
            'score': '',
        },
        {
            'number': 5,
            'info': '',
            'description': 'Schrok u bij lichamelijk contact?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'heel erg',
            'max_description': 'helemaal niet',
            'score': '',
        },
        {
            'number': 6,
            'info': '',
            'description': 'Had u vertrouwen in uw medemens?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'zeer wantrouwend',
            'max_description': 'vol vertrouwen',
            'score': '',
        },
        {
            'number': 7,
            'info': '',
            'description': 'Ging u uit van uw eigen capaciteiten?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'paste mij erg aan',
            'max_description': 'volgde mijn eigen koers',
            'score': '',
        },
        {
            'number': 8,
            'info': '',
            'description': 'Hoe verliep het contact met anderen?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'afstandelijk',
            'max_description': 'persoonlijk, diepgaand',
            'score': '',
        },
        {
            'number': 9,
            'info': '',
            'description': 'Ervaarde u vrijheid in uw leven?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'zeer beperkt',
            'max_description': 'onbevangen en vrij',
            'score': '',
        },
        {
            'number': 10,
            'info': '',
            'description': 'Voelde u zich verantwoordelijk voor uw eigen leven?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'werd geleefd',
            'max_description': 'ging mijn eigen gang',
            'score': '',
        },
        {
            'number': 11,
            'info': '',
            'description': 'Heeft u van het leven genoten?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'het was niet leuk',
            'max_description': 'volop genieten',
            'score': '',
        },
        {
            'number': 12,
            'info': '',
            'description': 'Heeft u de mensen die u dierbaar zijn, aangeraakt?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'helemaal niet',
            'max_description': 'heel vaak',
            'score': '',
        },
        {
            'number': 13,
            'info': '',
            'description': 'Werd u aangeraakt door mensen die u dierbaar zijn?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'helemaal niet',
            'max_description': 'heel vaak',
            'score': '',
        },
        {
            'number': 14,
            'info': '',
            'description': 'Voelde u innerlijke rust?',
            'question_type': 'multiple_choice_min_max_horizontal',
            'items': [
                {
                    'value': 1,
                },
                {
                    'value': 2,
                },
                {
                    'value': 3,
                },
                {
                    'value': 4,
                },
                {
                    'value': 5,
                },
            ],
            'min_description': 'nooit',
            'max_description': 'altijd',
            'score': '',
        },
    ],
    'scores': [
        {
            'description': 'Psychisch welbevinden',
            'formule': 'S1+S9+S11+S14',
            'min': 4,
            'max': 20,
            'score_conclusion': 'high',
            'score': '',
            'treatment': 'Kwaliteit van leven',
            'goal': 'Optimaliseren van het psysich welbevinden',
            'operation': '...',
            'goalscore': 0,
        },
        {
            'description': 'Lichamelijk welzijn',
            'formule': 'S2+S3',
            'min': 2,
            'max': 10,
            'score_conclusion': 'high',
            'score': '',
            'treatment': 'Kwaliteit van leven',
            'goal': 'Optimaliseren van het lichamelijk welzijn',
            'operation': '...',
            'goalscore': 0,
        },
        {
            'description': 'Autonomie',
            'formule': 'S4+S7+S10',
            'min': 3,
            'max': 15,
            'score_conclusion': 'high',
            'score': '',
            'treatment': 'Kwaliteit van leven',
            'goal': 'Optimaliseren van de autonomie',
            'operation': '...',
            'goalscore': 0,
        },
        {
            'description': 'Relatie met anderen',
            'formule': 'S5+S6+S8',
            'min': 3,
            'max': 15,
            'score_conclusion': 'high',
            'score': '',
            'treatment': 'Kwaliteit van leven',
            'goal': 'Verbeteren van de relatie met anderen',
            'operation': '...',
            'goalscore': 0,
        },
        {
            'description': 'Aanraken en aangeraakt worden',
            'formule': 'S12+S13',
            'min': 2,
            'max': 10,
            'score_conclusion': 'high',
            'score': '',
            'treatment': 'Kwaliteit van leven',
            'goal': 'Verbeteren van aanraken en aangeraakt worden',
            'operation': '...',
            'goalscore': 0,
        },
    ],
}

export default questionnaire_hws_v1