<template>
  <div v-show="sessions.length > 0" class="row mb-1" @click="collapsed = !collapsed">
    <div class="col-10 text-start text-primary h6 fw-bold opacity-50">
      {{ overviewTitle }} ({{ sessions.length}})
    </div>
    <div class="col-2 text-end">
      <img v-show="collapsed" src="@/assets/icons/up-16.png" alt="" class="img-fluid"/>
      <img v-show="!collapsed" src="@/assets/icons/down-16.png" alt="" class="img-fluid"/>
    </div>
  </div>
  <div
      v-for="session in filteredSessions" :key="session.id"
      class="row mb-2"
      @click="session.active = !session.active"
      v-show="!collapsed"
  >
    <div class="col">
      <div class="card text-secondary">
        <div class="card-body rounded-2" :class="session.active ? 'bg-light' : ''">
          <div class="row mb-2" >
            <div class="col fs-5 text-start">
              {{ session.product_description }}
            </div>
          </div>
          <div class="row">
            <div class="col text-start">
              <div class="d-inline-block pe-2">
                <img src="@/assets/icons/clock-24.png" alt="" class="img-fluid"/>
              </div>
              <div class="d-inline-block fw-bold">
                {{ new Date(session.start_date.seconds * 1000).toLocaleDateString() }}&nbsp;&nbsp;
              </div>
              <div class="d-inline-block">
                {{ new Date(session.start_date.seconds * 1000).toLocaleTimeString().substr(0,5) }} -
                {{ new Date(session.end_date.seconds * 1000).toLocaleTimeString().substr(0,5) }}
              </div>
            </div>
          </div>
          <div v-show="session.active" class="row">
            <div class="col text-start">
              <div class="row">
                <div class="col">
                  <hr>
                  <div class="row">
                    <div class="col text-start">
                      <div class="d-inline-block text-primary opacity-75">
                        Omschrijving:
                      </div>
                      <div class="d-inline-block ms-2">
                        {{ session.product_description }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col text-start">
                      <div class="d-inline-block text-primary opacity-75">
                        Medewerker:
                      </div>
                      <div class="d-inline-block ms-2">
                        {{ session.employee_name }}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col text-start">
                      <div class="d-inline-block text-primary opacity-75">
                        Locatie:
                      </div>
                      <div class="d-inline-block ms-2">
                        {{ session.location_description }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col text-start">

            </div>
            <div class="col-2 text-end">
              <img v-show="session.active" src="@/assets/icons/up-16.png" alt="" class="img-fluid"/>
              <img v-show="!session.active" src="@/assets/icons/down-16.png" alt="" class="img-fluid"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SessionsOverview",
  props: {
    sessions: Array,
    overviewTitle: String,
    mode: String,
  },
  data() {
    return {
      collapsed: true,
    }
  },
  computed: {
    filteredSessions() {
      let items = []
      this.sessions.forEach((session, counter) => {
        if (this.mode === 'future' && counter === 0) {
          session.active = true
        }
        items.push(session)
      })
      return items
    }
  },
  mounted() {
    this.collapsed = (this.mode !== 'future')
  },
}
</script>

<style scoped>

</style>