<template>
  <div class="pt-1 pb-1 ps-2 pe-2" :class="['message', { dark }]">
    <div class="row">
      <div class="col small fw-bold text-start">
        {{ message.sender_name }}
      </div>
      <div class="col small text-end opacity-75">
        {{ new Date(message.created.seconds * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', }) }}
      </div>
    </div>
    <div class="row">
      <div class="col text-start">
        {{ message.text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PortalMessage.vue",
  props: [
    'text', // Content of the message
    'author', // Author of the message
    'dark', // Background variant of the box
    'date',
      'message',
  ],
}
</script>

<style scoped>
.message {
  background: #e7e7e7;
  border-radius: 10px;
  width: fit-content;
}
.message.dark {
  //background: #e9eaf6;
  background: #308539;
}
</style>