<template>
  <div class="messageroom">
    <div class="messages" ref="mr">
      <PortalMessage
          v-for="message in messages"
          :key="message.id"
          class="shadow-sm"
          :class="['message', { right: (message.type === 'client') }]"
          :message="message"
          :dark="message.type === 'client'"
          :text="message.text"
          :author="message.sender_name"
      />
    </div>
  </div>

  <div class="card ms-2 me-2 mt-3">
    <div class="card-body">
      <div class="row">
        <div class="col-10 text-end pe-1">
          <input type="text" class="form-control text-secondary" v-model="message" @keyup.enter="sendMessage()"/>
        </div>
        <div class="col-2 ps-1 pe-1 pt-1">
          <img src="@/assets/icons/send-32.png" alt="send" class="img-fluid" @click="sendMessage()"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClientPortalDataService from "@/firebase/ClientPortalDataService";
import store from "@/store";
import PortalMessage from "@/components/messages/PortalMessage";

export default {
  name: "PortalMessageRoom",
  components: { PortalMessage },
  data() {
    return {
      messages: [],
      message: '',
    }
  },
  methods: {
    async sendMessage() {
      await ClientPortalDataService.AddClientPortalMessage(store.state.currentClientId, store.state.dossierId, this.message)

      const dataObj = {
        created: new Date(),
        type: 'message',
        client_id: store.state.currentClientId,
        dossier_id: store.state.dossierId,
        status: 'open',
      }
      await ClientPortalDataService.AddAlert(store.state.client.employee_id, dataObj)


      this.messages = await ClientPortalDataService.GetClientPortalMessages(store.state.currentClientId, store.state.dossierId)
      this.message = ''
      await this.scrollToEndOfMessages()
    },
    async scrollToEndOfMessages() {
      const sb = await this.$refs.mr.lastElementChild
      sb?.scrollIntoView({behavior: 'smooth',})
    },
  },
  async mounted() {
    this.messages = await ClientPortalDataService.GetClientPortalMessages(store.state.currentClientId, store.state.dossierId)
    await ClientPortalDataService.SetClientPortalMessagesRead(store.state.currentClientId, store.state.dossierId, 'therapist')

  },
  async updated() {
    await this.scrollToEndOfMessages()
  },
}
</script>

<style scoped>
.messageroom {
  display: flex;
  flex-direction: column-reverse;
  height: 50vh;
  overflow-y: auto;
}
.messages {
  flex-grow: 1;
  overflow: auto;
  padding: 1rem;
}
.message + .message {
  margin-top: .5rem;
}
.message.right {
  margin-left: auto;
}
.message {
  background: #e7e7e7;
  border-radius: 10px;
  padding: 1rem;
  width: fit-content;
}
.message.dark {
  background: #e9eaf6;
}
</style>