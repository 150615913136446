<template>
  <div v-if="question.question_type === 'multiple_choice_min_max_horizontal'" class="row">
    <div class="col">
      <div class="row ms-3">
        <div v-for="(item, item_index) in question.items" :key="item" class="col text-center">
          <div class="form-check form-check-inline m-0 p-0">
            <input class="form-check-input" type="radio" v-model="score" :name="'question' + question.number" :value="item.value" @change="processScore(item.value)" @mouseup="nextQuestion()">
            <label class="form-check-label" :for="'question_' + question.number + 'answer_' + item_index">
              {{ item.description }}
            </label>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col text-start">
          {{ question.min_description }}
        </div>
        <div class="col text-end">
          {{  question.max_description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "MultipleChoiceMinMaxHorizontal",
  props: ['question'],
  data() {
    return {
      score: '',
    }
  },
  methods: {
    nextQuestion() {

    },
    processScore(score) {
      store.state.questionnaire.questions[store.state.currentQuestion - 1].score = score
      setTimeout(() => {
        if (store.state.currentQuestion < store.state.questionnaire.questions.length) {
          store.state.currentQuestion++
        }
      },500)
    }
  }
}
</script>

<style scoped>

</style>