import { createStore } from 'vuex'

export default createStore({
    state: {
        client: {
            client: {
                first_name: '',
            },
            clientPortalSettings: {},
            requests: [],
            messages: [],
            exercises: [],
            client_id: '',
            employee_id: '',
        },
        currentClientId: null,
        dossierId: '',
        clinimetricsId: '',
        currentRequest: null,
        questionnaire: {
            description: '',
            questions: [],
        },
        currentQuestion: 1,
    },
    mutations: {
        async SET_CURRENT_CLIENT_ID(state, id) {
            state.currentClientId = id
        },
    },
    actions: {
        setCurrentClientId(context, id) {
            context.commit('SET_CURRENT_CLIENT_ID', id)
        },
    },
})