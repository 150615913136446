<template>
  <div class="row p-2">
    <div class="col"/>
    <div class="col-lg-4">
      <div class="card shadow">
        <div class="card-body text-secondary">
          <div class="custom-shape-divider-bottom-1693904851 opacity-25" style="z-index: 0">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
              <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
              <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
            </svg>
          </div>
          <div class="row mt-2 mb-4">
            <div class="col">
              <div class="h3">
                Goedemorgen {{ clientData ? clientData.client.first_name : '' }}
              </div>
            </div>
            <div class="col-1 text-end p-0">
              <div class="dropdown">
                <button class="btn p-0" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img src="@/assets/icons/menu-vertical-24.png" alt="menu" class="img-fluid mt-0 me-0">
                </button>
                <ul class="dropdown-menu dropdown-menu-end" style="background-color: rgb(245,252,253);">
                  <li>
                    <a class="dropdown-item me-3 text-secondary" v-on:click="handleLogout()">
<!--                      <img src="@/assets/blue/checkbox-16.png" alt="edit" class="img-fluid me-2">-->
                      Uitloggen
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="row p-0 mb-3">
            <div class="col-6">
              <RouterLink :to="settings.showAppointments ? '/calendar' : '/'" style="color: inherit; text-decoration: inherit;">
                <MenuButton :label="'Afspraken'" :image="'calendar-64.png'" :enabled="settings.showAppointments"/>
              </RouterLink>
            </div>
            <div class="col-6">
              <RouterLink :to="settings.showDossier ? '/dossier' : '/'" style="color: inherit; text-decoration: inherit;">
                <MenuButton :label="'Dossier'" :image="'health-book-64.png'" :enabled="settings.showDossier"/>
              </RouterLink>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6">
              <RouterLink :to="settings.showInvoices ? '/invoices' : '/'" style="color: inherit; text-decoration: inherit;">
                <MenuButton :label="'Facturen'" :image="'invoice-64.png'" :enabled="settings.showInvoices"/>
              </RouterLink>
            </div>
            <div class="col-6">
              <RouterLink :to="settings.showMessages ? '/messages' : '/'" style="color: inherit; text-decoration: inherit;">
                <MenuButton :label="'Berichten'" :image="'message-64.png'" :enabled="settings.showMessages" :counter="messages ? messages.length : 0" :badgecolor="'button-badge-red'"/>
              </RouterLink>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-6">
              <RouterLink :to="settings.showExercises ? '/exercises' : '/'" style="color: inherit; text-decoration: inherit;">
                <MenuButton :label="'Oefeningen'" :image="'exercise-64.png'" :enabled="settings.showExercises" :counter="exercises ? exercises.length : 0" :badgecolor="'button-badge-blue'"/>
              </RouterLink>
            </div>
            <div v-show="requests ? requests.length > 0 : false" class="col-6">
              <RouterLink to="/requests" style="color: inherit; text-decoration: inherit;">
                <MenuButton :label="'Verzoeken'" :image="'request-64.png'" :enabled="true" :counter="requests ? requests.length : 0" :badgecolor="'button-badge-red'"/>
              </RouterLink>
            </div>
          </div>
          <div style="margin-top: 100px;"/>
        </div>
      </div>
    </div>
    <div class="col"/>
  </div>
</template>

<script>
import MenuButton from "@/components/general/MenuButton";
import ClientPortalDataService from "@/firebase/ClientPortalDataService";
import store from "@/store";
import { logout } from '@/services/Auth';
import router from "@/router";

export default {
  name: "WelcomeTile",
  components: {MenuButton},
  data() {
    return {
      selectedButton: [false, false, false, false],
    }
  },
  methods: {
    async handleLogout() {
      await logout()
      await router.push('/login')
    },
  },
  computed: {
    clientData() {
      return store.state.client
    },
    settings() {
      return store.state.client.clientPortalSettings
    },
    requests() {
      return store.state.client.requests
    },
    messages() {
      if (store.state.client.messages) {
        return store.state.client.messages
            .filter((item) => item.read === false)
      } else {
        return []
      }

    },
    exercises() {
      return store.state.client.exercises
    },
  },
  async mounted() {
    store.state.client = await ClientPortalDataService.GetClientData(store.state.currentClientId)
    if (store.state.client.status !== 'empty') {
      store.state.client.requests = await ClientPortalDataService.GetClientPortalRequests(store.state.currentClientId, store.state.dossierId)
      store.state.client.messages = await ClientPortalDataService.GetClientPortalMessages(store.state.currentClientId, store.state.dossierId)
      store.state.client.exercises = await ClientPortalDataService.GetClientPortalExercises(store.state.currentClientId, store.state.dossierId)
    }
  },
}
</script>

<style scoped>

</style>