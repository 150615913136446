class ClinimetricsFormulaService {
    countFormula(questionnaire, formula) {
        let answers = []
        questionnaire.questions.forEach((question) => {
            answers.push(question.score)
        })

        try {
            for (let i = questionnaire.questions.length + 1; i >= 1; i--) {
                switch(questionnaire.id) {
                    case '4DKL_v1':
                        formula = formula.replace('S' + i, this.recalc4DKLScore(answers[i-1]))
                        break
                    default:
                        formula = formula.replace('S' + i, answers[i-1])
                        break
                }
            }
            return eval(formula)
        } catch (error) {
            return ''
        }
    }
    recalc4DKLScore(value) {
        try {
            if (value === 1) {
                return 0
            } else if (value === 2) {
                return 1
            } else {
                return 2
            }

        } catch {
            return 0
        }
    }
    countSMSKScore(answers) {
        let score = 0
        answers.forEach((answer, counter) => {
            if (counter === 0) {
                score = score + (Number(answer) < 5 ? 0 : Number(answer) < 7 ? 1 : Number(answer) < 9 ? 2 : 3)
            } else {
                score = score + answer
            }
        })

        return score
    }
}

export default new ClinimetricsFormulaService()