import { db, auth } from "@/firebase/init"
import {
    collection,
    query,
    getDocs,
    where,
    doc,
    setDoc,
    orderBy,
    addDoc,
    updateDoc,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from 'firebase/auth';
import store from '@/store'

class ClientPortalDataService {
    async CheckRegistration(email, registration) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const invitationStatus = {
                    status: true,
                    message: '',
                    id: '',
                }
                const invitationRef = collection(db, '/invitations')
                const invitationQuery = query(invitationRef, where('registration_code', '==', String(registration)), where('email', '==', email))

                let invitations = []
                let data = await getDocs(invitationQuery)
                data.forEach((doc) => {
                    let invitation = doc.data()
                    invitation.id = doc.id
                    invitations.push(invitation)
                })
                if (invitations.length === 0) {
                    invitationStatus.status = false
                    invitationStatus.message = 'Combinatie emailadres en registratiecode kan niet gevonden worden'
                } else if (invitations.length === 1) {
                    invitationStatus.status = true
                    invitationStatus.message = ''
                    invitationStatus.clientId = invitations[0].client_id
                    invitationStatus.customerId = invitations[0].customer_id
                    invitationStatus.id = invitations[0].id
                } else {
                    invitationStatus.status = false
                    invitationStatus.message = 'Er zijn meerdere resultaten'
                    invitationStatus.id = ''
                }
                resolve(invitationStatus)

            } catch (e) {
                reject({
                    status: false,
                    message: e,
                })
            }
        })
    }
    async UpdateInvitation(invitationId, dataObj) {
        const docRef = doc(db, 'invitations', invitationId)

        await setDoc(docRef, dataObj, { merge: true })
            .catch((error) => {
                console.log(error)
            })
    }
    async CreateClientPortalUser(email, password, clientId, customerId) {
        await createUserWithEmailAndPassword(auth, email, password)
            .catch((error) => {
                console.log(error.code, error.message)
            })
            .finally(() => {
                console.log('user created')
            })

        await setDoc(doc(db,  'users/', email), {})
        await setDoc(doc(db,  'users/' + email + '/clients', clientId), {
            client_id: clientId,
            customer_id: customerId,
        })
    }
    async SetClientPortalMessagesRead(clientId, dossierId, messageType) {
        console.log('set client portal messages read')
        const messagesRef = collection(db, '/clients/' + clientId + '/dossiers/' + dossierId + '/messages')
        const messagesQuery = query(messagesRef, where('read', '==', false), where('type', '==', messageType))

        let data = await getDocs(messagesQuery)
        data.forEach((doc) => {
            updateDoc(doc.ref, {
                read: true,
            })
            //const docRef = doc(db, '/clients/' + clientId + '/dossiers/' + dossierId + '/messages', item.id)
            // updateDoc(doc, {
            //     read: true,
            // })
        })
    }
    async GetClientPortalMessages(clientId, dossierId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const messagesRef = collection(db, '/clients/' + clientId + '/dossiers/' + dossierId + '/messages')
                const messagesQuery = query(messagesRef, orderBy('created'))

                let messages = []
                let data = await getDocs(messagesQuery)
                data.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    messages.push(doc.data())
                })
                resolve(messages)

            } catch (e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async AddClientPortalMessage(clientId, dossierId, messageText) {
        const colRef = collection(db, 'clients/' + clientId + '/dossiers/' + dossierId + '/messages')

        const dataObj = {
            text: messageText,
            sender: store.state.currentClientId,
            sender_name: store.state.client.client.first_name,
            type: 'client',
            created: new Date(),
        }

        await addDoc(colRef, dataObj)
            .catch((error) => {
                console.log(error)
            })
    }
    async GetClientPortalRequests(clientId, dossierId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const requestsRef = collection(db, '/clients/' + clientId + '/dossiers/' + dossierId + '/requests')
                const requestsQuery = query(requestsRef)

                let requests = []
                let data = await getDocs(requestsQuery)
                data.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    requests.push(data)
                })
                resolve(requests)

            } catch (e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async UpdateRequest(clientId, dossierId, requestId, dataObj) {
        const requestsRef = collection(db, '/clients/' + clientId + '/dossiers/' + dossierId + '/requests')
        const requestDoc = doc(requestsRef, requestId)

        await setDoc(requestDoc, dataObj, { merge: true })
            .catch((error) => {
                console.log(error)
            })
    }
    async GetClientPortalExercises(clientId, dossierId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const requestsRef = collection(db, '/clients/' + clientId + '/dossiers/' + dossierId + '/exercises')
                const requestsQuery = query(requestsRef)

                let requests = []
                let data = await getDocs(requestsQuery)
                data.forEach((doc) => {
                    let data = doc.data()
                    data.client_exercise_id = doc.id
                    requests.push(data)
                })
                resolve(requests)

            } catch (e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async AddExerciseComment(clientId, dossierId, exerciseId, dataObj) {
        const colRef = collection(db, 'clients/' + clientId + '/dossiers/' + dossierId + '/exercises/' + exerciseId + '/comments')

        dataObj.sender = store.state.currentClientId
        dataObj.sender_name = store.state.client.client.first_name

        await addDoc(colRef, dataObj)
            .catch((error) => {
                console.log(error)
            })
    }
    async GetExerciseComments(clientId, dossierId, exerciseId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const commentsRef = collection(db, '/clients/' + clientId + '/dossiers/' + dossierId + '/exercises/' + exerciseId + '/comments')
                const commentsQuery = query(commentsRef)

                let comments = []
                let data = await getDocs(commentsQuery)
                data.forEach((doc) => {
                    let data = doc.data()
                    comments.push(data)
                })
                resolve(comments)

            } catch (e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async GetClientData(clientId) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async function (resolve, reject) {
            try {
                const clientsRef = collection(db, '/clients/' + clientId + '/dossiers')
                const clientsQuery = query(clientsRef)

                //const docSnap = await getDoc(doc(db, '/clients/' + clientId + '/dossiers', 'qv4c2T6Z39wvzLq48gfm'))
                //const docSnap = await getDoc(doc(db, '/clients', clientId))
                let dossiers = []
                let data = await getDocs(clientsQuery)
                data.forEach((doc) => {
                    let data = doc.data()
                    data.id = doc.id
                    dossiers.push(data)
                })
                if (dossiers.length > 0) {
                    store.state.dossierId = dossiers[0].id
                    resolve(dossiers[0])
                } else {
                    resolve({
                        client: {
                            first_name: '',
                            last_name: '',
                        },
                        clientPortalSettings: {
                            showAppointments: false,
                            showDossier: false,
                            showExercises: false,
                            showInvoices: false,
                            showMessages: false,
                        },
                        exercises: [],
                        invoices: [],
                        sessions: [],
                        targets: [],
                        status: 'empty',
                        client_id: '',
                        dossier_id: '',
                        employee_id: '',
                    })
                }

            } catch (e) {
                console.log(e)
                reject(null)
            }
        })
    }
    async AddAlert(employeeId, dataObj) {
        const colRef = collection(db, 'employees/' + employeeId + '/alerts/')
        await addDoc(colRef, dataObj)
    }
}

export default new ClientPortalDataService()