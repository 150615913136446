<template>
  <div v-show="invoices.length > 0" class="row mt-4 mb-1" @click="collapsed = !collapsed">
    <div class="col-10 text-start text-primary h6 fw-bold opacity-50">
      {{ overviewTitle }} ({{ invoices.length }})
    </div>
    <div class="col-2 text-end">
      <img v-show="collapsed" src="@/assets/icons/up-16.png" alt="" class="img-fluid"/>
      <img v-show="!collapsed" src="@/assets/icons/down-16.png" alt="" class="img-fluid"/>
    </div>
  </div>
  <div
      v-for="invoice in invoices" :key="invoice.id"
      class="row mb-2"
      @click="invoice.active = !invoice.active"
      v-show="!collapsed"
  >
    <div class="col">
      <div class="card shadow text-secondary">
        <div class="card-body rounded-2" :class="invoice.active ? 'bg-light' : ''">
          <div class="row mb-2" >
            <div class="col-11 fs-5 text-start">
              {{ invoice.description }}
            </div>
            <div class="col-1 p-0">
              <img v-show="invoice.payment_status === 'paid'" src="@/assets/icons/paid-24.png" alt="paid" class="img-fluid opacity-75"/>
              <img v-show="invoice.payment_status === 'not_paid'" src="@/assets/icons/not-paid-24.png" alt="paid" class="img-fluid opacity-75"/>
            </div>
          </div>
          <div class="row">
            <div class="col text-start">
              <div class="d-inline-block pe-2">
                <img src="@/assets/icons/calendar-24.png" alt="" class="img-fluid"/>
              </div>
              <div class="d-inline-block fw-bold">
                {{ new Date(invoice.invoice_date).toLocaleDateString() }}
              </div>
            </div>
          </div>
          <div v-show="invoice.active" class="row">
            <div class="col">
              <div class="row">
                <div class="col">
                  <hr>
                </div>
              </div>
              <div class="row">
                <div class="col text-start">
                  <div class="d-inline-block text-primary opacity-75">
                    Factuurnummer:
                  </div>
                  <div class="d-inline-block ms-2">
                    {{ invoice.invoice_number }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col text-start">
                  <div class="d-inline-block text-primary opacity-75">
                    Factuurbedrag:
                  </div>
                  <div class="d-inline-block ms-2">
                    € {{ Number(invoice.total_amount_incl_vat / 100).toFixed(2) }}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col text-start">
                  <div class="d-inline-block text-primary opacity-75">
                    Betaalstatus:
                  </div>
                  <div class="d-inline-block ms-2 fw-bold">
                    {{ invoice.payment_status === 'paid' ? 'betaald' : 'niet betaald' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div v-show="!invoice.active" class="col">

            </div>
            <div v-show="invoice.active" class="col text-start">
              <img src="@/assets/icons/download-24.png" alt="download" class="img-fluid"/>
            </div>
            <div class="col-2 text-end">
              <img v-show="invoice.active" src="@/assets/icons/up-16.png" alt="" class="img-fluid"/>
              <img v-show="!invoice.active" src="@/assets/icons/down-16.png" alt="" class="img-fluid"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvoicesOverview",
  props: {
    invoices: Array,
    overviewTitle: String,
  },
  data() {
    return {
      collapsed: true,
    }
  },
}
</script>

<style scoped>

</style>